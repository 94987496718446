import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBannerFmcg'
import Footer from "../components/App/Footer"
//import TestimonialsStyleOne from '../components/Testimonials/TestimonialsStyleOne'
import FmcgDetail from '../components/Testimonials/trading'

const Fmcg = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Fast Moving Consumer Goods" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Fast Moving Consumer Goods" 
            />
            {/*<TestimonialsStyleOne />*/}
            <FmcgDetail />
            <Footer />
        </Layout>
    );
}

export default Fmcg